<template>
    <div class="log-wrapper">
      <!-- <template v-if="fetched">
        <div class="table">
          <div class="table-header">
            <div class="header__item"><a id="dates" class="filter__link filter__link--date" href="javascript:;">Date</a></div>
            <div class="header__item"><a id="message" class="filter__link" href="javascript:;">Mensagem</a></div>
            <div class="header__item"><a id="tags" class="filter__link" href="javascript:;">Tag</a></div>
            <div class="header__item"><a id="type" class="filter__link" href="javascript:;">Tipo</a></div>
            <div class="header__item"><a id="draws" class="filter__link filter__link--number" href="javascript:;">Draws</a></div>
            <div class="header__item"><a id="total" class="filter__link filter__link--number" href="javascript:;">Total</a></div>
          </div>
          <div class="table-content">
            <template v-for="(log, index) in logs">
              <log-item v-show="log" :key="log.id" :item='log' :index='index' :total="totalItems"/>
            </template>
          </div>
        </div>
        <pagination v-model="currentPage" :total-pages="pages" @change="fetch" />
      </template>
      <template v-else>
        <div class="loading-wrapper">
          <LoadingAnim />
        </div>
      </template> -->
      <Card ref="card">
        <template #header>
          <ListGroup top-right>
            <ListGroupItem>
              <a role="button" @click="fetch(1)"
                ><span class="material-icons-outlined"> refresh </span></a
              >
            </ListGroupItem>
          </ListGroup>
        </template>
        <div class="row">
          <Table :fields="tableFields" :items="logs" :busy="loading" />
        </div>
        <div class="row">
          <pagination v-model="currentPage" :total-pages="pages" @change="fetch" />
        </div>
      </Card>
    </div>
</template>
<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// import LogItem from '@/components/rebranding/logs-report/item/Index.vue';
import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import axios from '@/plugins/axios';
// import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import Card from '@/components/rebranding/card/Card.vue';
import Table from '@/components/rebranding/table/Table.vue';
import ListGroup from '@/components/rebranding/list/ListGroup.vue';
import ListGroupItem from '@/components/rebranding/list/ListGroupItem.vue';
import $ from 'jquery';

const webApi = axios();

export default {
  name: 'LogDetail',
  components: {
    Pagination,
    // LoadingAnim,
    // LogItem,
    Card,
    Table,
    ListGroup,
    ListGroupItem,
  },
  props: {
    dealId: {
      type: String,
    },
  },
  data() {
    return {
      currentPage: 1,
      pages: 1,
      fetched: true,
      loading: true,
      logs: [],
      tableFields: [
        {
          key: 'created_at',
          label: this.$t('app.date'),
          type: 'date',
        },
        {
          key: 'content',
        },
        {
          key: 'tags',
          label: 'Tags',
        },
        {
          key: 'payload',
          label: 'Payload',
        },
      ],
      totalItems: 0,
    };
  },
  mounted() {
    this.$root.$on('refreshLogList', () => {
      this.fetch();
    });
  },
  beforeDestroy() {
    // Destroying instance to avoid multiple future further calls
    this.$root.$off('refreshLogList');
  },
  watch: {
    fetched: {
      handler(val, oldVal) {
        console.log('Watch: fetched watch: ', val, '\nWatch: fetched old value: ', oldVal);
        // this.sortTable();
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    setTimeout(() => {
      this.fetch(1);
    }, 500);
  },
  methods: {
    fetch(page) {
      // this.fetched = true;
      this.loading = true;
      this.currentPage = page;
      // this.dealId = '7eb9104d-ffe7-4d3f-886d-b14b3ebc6347';
      let query = '';
      if (this.dealId) {
        query = `/custom-logs?page=${this.currentPage}&deal_id=${this.dealId}`;
      } else {
        query = `/custom-logs?page=${this.currentPage}`;
      }
      webApi.get(query).then(
        (response) => {
          console.log('Log response:\n ', response);
          // this.fetched = false;
          this.loading = false;
          this.logs = response.data.data;
          this.totalItems = this.logs.length;
          this.pages = response.data.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>
<style lang="scss">
$base-spacing-unit: 24px;
$half-spacing-unit: $base-spacing-unit / 2;

$color-alpha: #1772FF;
$color-form-highlight: #F7F9FB;

.table {
	width:100%;
	// border:1px solid $color-form-highlight;
}

.table-header {
	display:flex;
	width:100%;
	// background:#000;
	padding:($half-spacing-unit * 1.5) 0;
}

.table-row {
	display:flex;
	width:100%;
	padding:($half-spacing-unit * 1.5) 0;

	&:nth-of-type(odd) {
		// background:$color-form-highlight;
	}
}

.table-data, .header__item {
	flex: 1 1 20%;
	// text-align:center;
  &:first-child {
    max-width: 8%;
    margin-right: 1rem;
  }
  /* &:last-child, &:nth-child(3){
    max-width: 5%;
  } */
}

.header__item {
	// text-transform:uppercase;
  font-weight: 400;
}

.filter__link {
	color:var(--foreground);
	text-decoration: none;
	position:relative;
	display:inline-block;
	padding-left:$base-spacing-unit;
	padding-right:$base-spacing-unit;

	&::after {
		content:'';
		position:absolute;
		right:-($half-spacing-unit * 1.5);
		color:var(--foreground);
		font-size:$half-spacing-unit;
		top: 50%;
		transform: translateY(-50%);
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
	}

	&.desc::after {
		content: '\e5db'; // (desc)
	}

	&.asc::after {
		content: '\e5d8'; // (asc)

	}
}
</style>
